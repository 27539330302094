const en = {
  network: {
    tips: `The selected network is not currently supported!
    Please select the Matic (Polygon) network!'`,
    ev: "Please check whether you have installed any wallet software such as MetaMask, Trust Wallet, imToken, or TokenPocket.",
    copyight: process.env.VUE_APP_TITLE_NAME,
    all: "Max",
    bolck: "Dai Stablecoin",
    law: `<h3><strong style="color:yellow">This DAPP is not legally protected in certain countries and regions, please use it with caution!</strong></h3>
    <br/>
    <p><strong>Dear Users:</strong></p>
<br/>
    <p>Thank you for your attention and support for this DAPP. To ensure your legal rights and help you understand the related legal risks, we hereby issue the following important announcement:</p><br/>

    <p>This DAPP involves cryptocurrency investments and transactions, which may violate laws in certain countries and regions. According to the laws, regulations, and policies of local regulatory authorities, this DAPP cannot operate legally in certain countries and regions, therefore we declare the following:</p>
<br/>
    <h3><strong>1. Service Range Restrictions:</strong></h3><br/>
    <p style="color:yellow">This DAPP does not provide cryptocurrency investment and transaction services, nor does it offer any technical support or after-sales services, in the following countries and regions:</p><br/>
    <p style="color:yellow">China (including Mainland China, Hong Kong SAR, Macao SAR, and Taiwan), India, Algeria, Bangladesh, Morocco, Egypt, Nepal, Pakistan, Qatar, Iraq, Tunisia, Afghanistan, Bolivia.</p>
   <br/> <strong>We recommend that users in the above-mentioned countries and regions immediately stop using this DAPP to avoid possible legal risks.</strong>
<br/><br/>
    <h3><strong>2. Legal and Investment Risk Warning:</strong></h3><br/>
    <p>Any use of this DAPP for cryptocurrency investments and transactions in the above-mentioned countries and regions may result in serious legal consequences. Our team will not bear any legal responsibility arising from such actions. Additionally, the cryptocurrency market itself is highly volatile and unpredictable, and investment activities carry significant risks. Please make sure to fully understand and confirm the relevant risks before deciding whether to continue using this DAPP for investments and transactions.</p>
<br/>
    <h3><strong>3. User Acknowledgment:</strong></h3><br/>
    <p>If you continue to use this DAPP for cryptocurrency investments and transactions, it will be regarded as you having thoroughly read and understood the contents of this announcement, and you acknowledge that you are aware of and willing to bear the legal risks and investment risks that may arise. Any actions you take will be considered as voluntarily assuming the associated responsibilities, and the operating team will not be liable for any consequences that may arise.</p>
<br/>
    <h3><strong>4. Statement from the Operating Team:</strong></h3><br/>
    <p>This DAPP is developed and operated by the Bull Developer team. We have always adhered to the principles of compliance, aiming to provide users with high-quality services. However, due to the special legal environment in certain countries and regions, we are unable to continue providing cryptocurrency-related services in these areas. We strongly recommend that all users understand and comply with the laws and regulations of their respective countries or regions before using this DAPP, to avoid potential legal and investment risks.</p>
<br/>
    <p><strong>Thank you again for your understanding and support. If you have any questions or need further assistance, please contact us through our official website.</strong></p>
<br/>
    <p><strong>Best wishes!</strong></p>
<br/>
    <p><strong>The Bull Developer Team</strong></p>`,
    protocol: `This Distributed Application (DApp) harbors uncertain investment risks. Before use, please ensure you read and fully understand the risk warnings and privacy policy.`,
    more: "More",
    media: "Related Social Media",
    stake:
      "Global opening on November 6, 2023, follow us on Telegram and Twitter!",
    withdraw:
      "Global opening on November 6, 2023, follow us on Telegram and Twitter!",
    Reinvest:
      "Global opening on November 6, 2023, follow us on Telegram and Twitter!",
    Getreward:
      "Global opening on November 6, 2023, follow us on Telegram and Twitter!",
    linkwallet: "Connect Wallet",
    notification: "The Falcon burn acceleration public test has successfully concluded, and we will now proceed with deploying the official contract. Stay tuned!",
    flashloansOK:'Start-up successful!',
    flashloansNo:'Start-up failed!',
    flashloansOnce:'Congratulations on getting one chance to experience!',
    flashloansError:'Sorry, the opportunity to experience has been used up!',
    falocnerror:'Falcon™ V2 Test qualification not granted!',
    falocninfo:'Falcon™ V2 for players approved at V3 level! Currently in public beta!',
    falconv4:'Falcon™V4 arbitrage cluster is in beta testing (28000 times/day)!'
  },
  leavel: {
    0:"0",
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
    9: "9",
},
ido:{
    select: "Select Community",
    flashmonkey: "FlashMonkey",
    flashdragon: "FlashDrgon",
    fashFalcon: "Falcon",
    powermonger: "PowerMonger",
    fashfalcon2: "FalconV2",
    other: "Other",
    Getmax: "Get Maximum",
    nowbuy: "Subscribe Now",
    mynumber: "My Falcon",
    myrelease: "Released",
    waitrelease: "Waiting for Release",
    get: "Withdraw",
    FalconF: "FlashFalcon(F)",
    FalconA: "FlashFalcon(A)",
    organization: "Organization",
    endTimes:'First Round Countdown',
    getmax:'Get Maximum',
    Authorization:"Authorization",
    Subscribe:"Subscribe Now",
    okSubscribe:"Subscribed",
    message:"Node Error, Please Change Node",
    end1:"First Round Ended",
    needStart:"Countdown to Start",
    getscusse:"Retrieval Successful",
    geterror:"Retrieval Failed"
  },
  top: {
    Account: "Account",
  },
  stake: {
    Balance: "Your Balance",
    Total: "Total Stake",
    Appoove: "Approve",
    Stake: "Stake",
    Appooveing: "Authorizing",
    AuthorSuccessful: "Authorization Successful",
    AuthorFailed: "Authorization Failed",
    Stakeing: "Depositing",
    StakeSuccessful: "Deposit Successful",
    StakeFailed: "Deposit Failed",
    StakeInfo: "Cannot be Less Than:",
    Activeaccount:"Activate account",
    active:"Activate",
    
  },
  swap:{
    Swap:"SWAP",
    paynameA:"Payment",
    paynameB:"Receipt",
    Approve:"Authorization",
    launchSwap:"Launch SWAP",
    recommend:"Recommend",
    swapError:"Swap error",
    swapNetwork:"Network error",
  },
  menu: {
    Home: "Home",
    Language: "Language",
    Document: "WhitePaper",
    Contract: "Contract",
    Falcon:"Falcon™",
    Watch: "Watch",
    Coming: "Coming Soon",
    EvDao: "Governance",
    Flashloan: "Launch",
    Developer:"Developer Docs",
    Launch:"Launch",
    xNumber:"Remaining",
    xTips:"Automatic processing",
    LogiV2:"Falcon™ V2",
    LogiV3:"Falcon™ V3",
    LogiV4:"Falcon™ V4",
    swap:"Swap",   
    LogiV2pro:"Falcon™ V2+",
    LogiV3tips:"Falcon™ V3 global public testing begins (daily requests 1000~7000), authorization required for the corresponding arbitrage contracts!",
    Appoove2:"Auto",
    Market:"Market",
    Ido:"Early Sub. Round 1",
    myIdo:"My Falcon",
    interest:"Profit",
  },
  Withdraw: {
    Withdraw: "Withdraw",
    Withdrawable: "Wallet",
    Earnings: "Earnings",
    Brokerage: "Brokerage amount",
    Reinvest: "Reinvest",
    Getreward: "Get Reward",
    Processing: "Processing",
    unsuccessfule: "Unsuccessfule",
    successful: "Successful!",
    WithdrawInfo1: "Please Enter the Withdrawal Amount",
    WithdrawInfo2: "Insufficient Quantity",
    burning:"FalconBurn",
    noBurn:"No Burn",
    addBurn:"Burn Earnings",
    addBurnCost:"Cost",
    BurnCount:"Balance",
    BurnSpeed:"Burn acceleration",
    needpay:"Need Pay",
    startBurn:"Launch Burn",
    Burning:"Burning",
    noBurn1:"Paused",
    cangetfalcon:"Expected to be withdrawable"
  },
  Team: {
    innver: "Innver",
    Teamstaked: "Team staked",
    leavel: "Honor",
    Team: "Team",
    Bind: "Bind Relation",
    info: "System temporarily paused binding, please wait for it to open.",
    Binding: "Binding in Progress",
    BindingSuccessful: "Binding Successful",
    BindingFailed: "Binding Failed",
    Bindinfo: "Please Enter the Inviter's Wallet Address",
  },
  Share: {
    Invitelink: "Invite Link",
    Intips: `The Dapp invitations are not currently available. 
            Please wait for the latest comImunity updates.`,
    copyTitle: "Invitation link",
    copyText: "The invitation link has been successfully copied!",
    copyCommnad: "Copy Link",
  },
  Contract: {
    contractaddress: "Contract Address",
    Copylink: "Polygonscan",
    CopylinkText: "Go to polygonscan!",
  },
  Wathch: {
    watchonlywallet: "Watch-only wallet",
    addwallet: "Add wallet",
    add: "Add",
    Leavel: "Leavel",
    share: "Share",
    fnOpen:
      "This wallet has not been invited to participate in this feature yet!！",
      nullString: "Please enter the wallet address!",
      addok:"Added successfully",
      addError:"Addition failed",
      addisExists:"This address already exists",
      addlist:"Watchlist",
  },
};
export default en;
