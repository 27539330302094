const ru = {
  network: {
    tips: `Выбранная сеть в настоящее время не поддерживается!
      Пожалуйста, выберите сеть Matic (Polygon)!`,
    ev: `Пожалуйста, проверьте, установлен ли у вас кошелек, такой как MetaMask,
     Trust Wallet, imToken, или TokenPocket.`,
     copyight: process.env.VUE_APP_TITLE_NAME,
    all: "Max",
    bolck: "Dai Stablecoin",
    law: `<h3><strong style="color:yellow">Этот DAPP не защищен законом в некоторых странах и регионах. Пожалуйста, используйте его с осторожностью!</strong></h3>
    <br/>
    <p><strong>Уважаемые пользователи:</strong></p>
<br/>
    <p>Спасибо за ваш интерес и поддержку данного DAPP. Чтобы гарантировать ваши законные права и помочь вам понять связанные с этим юридические риски, мы публикуем следующее важное объявление:</p><br/>

    <p>Этот DAPP связан с инвестициями и транзакциями с криптовалютой, которые могут нарушать законы в некоторых странах и регионах. Согласно местным законам, правилам и политике регулирующих органов, этот DAPP не может законно работать в определенных странах и регионах. Поэтому мы заявляем следующее:</p>
<br/>
    <h3><strong>1. Ограничения на оказание услуг:</strong></h3><br/>
    <p style="color:yellow">Этот DAPP не предоставляет услуги по инвестициям и транзакциям с криптовалютой, а также не предоставляет техническую поддержку или послепродажное обслуживание в следующих странах и регионах:</p><br/>
    <p style="color:yellow">Китай (включая материковый Китай, специальные административные районы Гонконг и Макао, а также Тайвань), Индия, Алжир, Бангладеш, Марокко, Египет, Непал, Пакистан, Катар, Ирак, Тунис, Афганистан, Боливия.</p>
   <br/> <strong>Мы рекомендуем пользователям из вышеперечисленных стран и регионов немедленно прекратить использование данного DAPP, чтобы избежать возможных юридических рисков.</strong>
<br/><br/>
    <h3><strong>2. Предупреждение о юридических и инвестиционных рисках:</strong></h3><br/>
    <p>Любое использование данного DAPP для инвестиций и транзакций с криптовалютой в вышеперечисленных странах и регионах может привести к серьезным юридическим последствиям. Наша команда не несет юридической ответственности за такие действия. Кроме того, рынок криптовалют сам по себе является высоковолатильным и непредсказуемым, а инвестиционная деятельность связана с значительными рисками. Пожалуйста, убедитесь, что вы полностью понимаете связанные с этим риски, прежде чем принимать решение о продолжении использования данного DAPP для инвестиций и транзакций.</p>
<br/>
    <h3><strong>3. Подтверждение пользователя:</strong></h3><br/>
    <p>Если вы продолжаете использовать данный DAPP для инвестиций и транзакций с криптовалютой, это будет рассматриваться как подтверждение того, что вы внимательно прочитали и поняли содержание этого объявления, и вы осведомлены о возможных юридических и инвестиционных рисках и готовы их нести. Все ваши действия будут считаться добровольным принятием соответствующей ответственности, и команда оператора не несет ответственности за возможные последствия.</p>
<br/>
    <h3><strong>4. Заявление команды операторов:</strong></h3><br/>
    <p>Этот DAPP разработан и управляется командой Bull Developer. Мы всегда следуем принципам соответствия законам и стремимся предоставлять пользователям высококачественные услуги. Однако из-за особой правовой среды в некоторых странах и регионах мы не можем продолжать предоставлять услуги, связанные с криптовалютой, в этих областях. Мы настоятельно рекомендуем всем пользователям ознакомиться с законами и правилами своей страны или региона перед использованием данного DAPP, чтобы избежать возможных юридических и инвестиционных рисков.</p>
<br/>
    <p><strong>Еще раз благодарим вас за понимание и поддержку. Если у вас есть вопросы или вам нужна дополнительная помощь, свяжитесь с нами через наш официальный сайт.</strong></p>
<br/>
    <p><strong>Желаем вам всего наилучшего!</strong></p>
<br/>
    <p><strong>Команда Bull Developer</strong></p>`,
    protocol: `Это распределенное приложение (DApp) имеет неопределенные инвестиционные риски. Перед использованием, пожалуйста, внимательно прочитайте и полностью поймите предупреждения о рисках и политику конфиденциальности.`,
    more: "подробности",
    media: "соответствующие социальные сети",
    stake:
      "Глобальное открытие 6 ноября 2023 года, подписывайтесь на нас в Telegram и Twitter!",
    withdraw:
      "Глобальное открытие 6 ноября 2023 года, подписывайтесь на нас в Telegram и Twitter!",
    Reinvest:
      "Глобальное открытие 6 ноября 2023 года, подписывайтесь на нас в Telegram и Twitter!",
    Getreward:
      "Глобальное открытие 6 ноября 2023 года, подписывайтесь на нас в Telegram и Twitter!",
    linkwallet: "Подключить кошелек",
    notification: "Публичное тестирование ускорения сжигания Falcon успешно завершено, и теперь мы приступаем к развертыванию официального контракта. Оставайтесь с нами!",
    flashloansOK:'Запуск успешен! ',
    flashloansNo:'Запуск не удался!',
    flashloansOnce:'Поздравляем с получением одного шанса на опыт!',
    flashloansError:'Извините, возможность опыта уже использована!',
    falocnerror:'Falcon™ V2 Тестовая квалификация не одобрена!',
    falocninfo:'Falcon™ V2 для игроков, одобренных на уровне V3! В настоящее время находится на стадии публичного тестирования!!',
    falconv4:'Арбитражный кластер Falcon™V4 находится в стадии бета-тестирования (28000 раз/день)!'
  },
  leavel: {
    0:"0",
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
    9: "9",
},

  top: {
    Account: "Аккаунт",
  },
  swap:{
    Swap:"Обмен",
    paynameA:"Платеж",
    paynameB:"Получение платежа",
    Approve:"Авторизация",
    launchSwap:"Начать обмен",
    recommend:"Рекомендация",
    swapError:"Ошибка обмена",
    swapNetwork:"Сетевая ошибка",
  },
  stake: {
    Balance: "Ваш Баланс",
    Total: "Общая Ставка",
    Appoove: "Утвердить",
    Stake: "Ставка",
    info: "Система временно приостановила привязку, пожалуйста, подождите, пока она не откроется.",
    Appooveing: "Авторизация",
    AuthorSuccessful: "Авторизация Успешно",
    AuthorFailed: "Авторизация Не Удалась",
    Stakeing: "Внесение",
    StakeSuccessful: "Успешное Внесение",
    StakeFailed: "Внесение Не Удалось",
    StakeInfo: "Не может быть Меньше, чем:",
    Activeaccount:"Активировать аккаунт",
    active:"Активировать"
  },
  menu: {
    Home: "Главная",
    Language: "Язык",
    Document: "Белая книга",
    Contract: "Контракт",
    Watch: "Наблюдение",
    Coming: "Скоро",
    EvDao: "BULLDAO",
    Flashloan: "Запуск Flashloan",
    Developer:"Документация для разработчиков",
    Launch:"Launch",
    xNumber:"Оставшийся",
    Falcon:"Falcon™",
    xTips:"Автоматическая обработка",
    LogiV2:"Falcon™ V2",
    LogiV3:"Falcon™ V3",
    LogiV4:"Falcon™ V4",
    Market:"Market",
    LogiV3tips:"Начинается глобальное публичное тестирование Falcon™ V3 (ежедневные запросы 1000~7000), требуется авторизация соответствующих арбитражных контрактов!",
    Appoove2:"Auto",
        Ido:"Falcon IDO",
    myIdo:"My Falcon",
    interest:"Прибыль",
  },
  ido:{
    select: "Select Community",
    flashmonkey: "FlashMonkey",
    flashdragon: "FlashDrgon",
    fashFalcon: "Falcon",
    powermonger: "PowerMonger",
    fashfalcon2: "FalconV2",
    other: "Other",
    Getmax: "Get Maximum",
    nowbuy: "Subscribe Now",
    mynumber: "My Falcon",
    myrelease: "Released",
    waitrelease: "Waiting for Release",
    get: "Withdraw",
    FalconF: "FlashFalcon(F)",
    FalconA: "FlashFalcon(A)",
    organization: "Organization",
    endTimes:'First Round Countdown',
    getmax:'Get Maximum',
    Authorization:"Authorization",
    Subscribe:"Subscribe Now",
    okSubscribe:"Subscribed",
    message:"Node Error, Please Change Node",
    end1:"First Round Ended",
    needStart:"Countdown to Start",
    getscusse:"Retrieval Successful",
    geterror:"Retrieval Failed"

  },
  Withdraw: {
    Withdraw: "Вывести",
    Withdrawable: "Доступно для вывода",
    Earnings: "Доход",
    Brokerage: "Сумма брокерского вознаграждения",
    Reinvest: "Реинвестировать",
    Getreward: "Получить награду",
    Processing: "Обработка",
    unsuccessfule: "Неуспешно",
    successful: "Успешно!",
    WithdrawInfo1: "Пожалуйста, введите количество для снятия",
    WithdrawInfo2: "недостаточное",
    burning:"FalconBurn",
    noBurn: "No Burn",
    addBurn: "Дополнительный доход",
    addBurnCost: "Дополнительные затраты",
    BurnCount: "Аккаунт сжигания",
    BurnSpeed: "Ускорение сжигания",
    needpay: "Требуется оплата",
    startBurn: "Начать ускорение",
    Burning: "Ускорение в процессе",
    noBurn1:"Paused",
    cangetfalcon:"Ожидается, что можно будет вывести"
  },
  Team: {
    innver: "Innver",
    Teamstaked: "Ставки команды",
    leavel: "Уровень",
    Team: "Команда",
    Bind: "Привязать отношения",
    info: "Система временно приостановила пригла",
    Binding: "Процесс Привязки",
    BindingSuccessful: "Привязка Успешна",
    BindingFailed: "Привязка Не Удалась",
    Bindinfo: "Пожалуйста, введите адрес кошелька пригласившего",
  },
  Share: {
    Invitelink: "Ссылка для приглашения",
    Intips:
      "Приглашения в Dapp в настоящее время недоступны. Пожалуйста, ждите последних обновлений от сообщества.",
    copyTitle: "Ссылка для приглашения",
    copyText: "Ссылка для приглашения успешно скопирована!",
    copyCommnad: "Скопировать ссылку",
  },
  Contract: {
    contractaddress: "Адрес контракта",
    Copylink: "Polygonscan",
    CopylinkText: "Перейти к polygonscan!",
  },
  Wathch: {
    watchonlywallet: "Кошелек только для наблюдения",
    addwallet: "Добавить",
    add: "Добавить",
    Leavel: "Уровень",
    share: "Поделиться",
    fnOpen: "Этот кошелек еще не был приглашен участвовать в этой функции!",
    nullString: "Пожалуйста, введите адрес кошелька!",
    addok:"Добавление выполнено",
    addError:"Добавление не удалось",
    addisExists:"Этот адрес уже существует",
    addlist:"Список наблюдения",
  },
};
export default ru;
