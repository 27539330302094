<template>
  <div class="stakewait">

    <div>
      合约广场
    </div>

    <!--loading start-->
    <div v-if="isloading" class="loading">
      <img :src="loadingimg" class="loadimg"/>
    </div>

    <!--loading end-->
  </div>
</template>

<style lang="less" scoped>

</style>
<script>
import swap_usdc from "@/assets/img/swap_usdc.svg";
import swap_usdt from "@/assets/img/swap_usdt.svg";
import swap_falcon from "@/assets/img/swap_falcon.svg";
import swap_matic from "@/assets/img/swap_matic.svg";
import swap_dai from "@/assets/img/swap_dai.svg";
import swap_close from "@/assets/img/swap_close.svg";
import swap_arrow from "@/assets/img/swap_arrow.svg";
import ProgressBar from "@/components/ProgressBar.vue";
// import Marquee from "@/components/Marquee.vue";
import web3Utils from "@/utils/web3Utils.js";
import dai from "@/assets/img/DaiStablecoin.svg";
import loadingimg from "@/assets/img/loading.svg";
import daimni from "@/assets/img/daimini.svg";
import stylea from "@/assets/img/stylea.png";
import contract from "@/plugins/contract.js";
import price from "@/plugins/price.js";
import sign from "@/plugins/sign.js";
import siteapi from "@/json/siteapi.json";
import Plugens from "@/plugins/index";
import axios from "axios";

export default {
  data() {
    return {
      swap_usdc,
      swap_usdt,
      swap_falcon,
      swap_matic,
      swap_dai,
      swap_arrow,
      swap_close,
      isButtonDisabled: true,
      depositDisabled: true,
      typeApprove: 0,
      typedeposit: 0,
      dai: dai,
      daimni: daimni,
      loadingimg: loadingimg,
      daiValue: "",
      daiOwnMoney: 0,
      stylea: stylea,
      stakedMoney: 0,
      currentProgress: 0,
      usersdata: {},
      sitedata: {},
      addresscode: "",
      empowerbutton: true,
      needinvest: "",
      Minimal: 0,
      isloading: false,
      list1: [],
      list2: [],
      pricelist1: [],
      pricelist2: [],
      isShown2: false,
      swap_decimalsA: 18,
      swap_Aname: "",
      swap_aimg: "",
      swap_Aaddress: "",
      swapimg2: ""
    };
  },
  components: {
    // Marquee,
    ProgressBar,
  },
  async mounted() {
    // Determine whether the wallet is connected
    if (typeof window.ethereum !== "undefined") {
      //console.log("Connected wallet");

      // Async obtaining network information
      window.ethereum
        .request({method: "eth_chainId"})
        .then((chainId) => {
          // is Polygon?
          if (chainId === "0x89") {
            // console.log("The current network is a Polygon chain");
            // this.$router.push({path:'/EvStake'});
          } else {
            //  console.log("The current network is not Polygon chain");
            this.$router.push({path: "/"});
            return;
          }
        })
        .catch((error) => {
          console.error("Failed to obtain network information. Procedure:", error);
        });
    } else {
      //console.log("Unconnected wallet");
    }
    this.sitedata = siteapi;
    const currentAddress = await web3Utils.connectWallet();
    // console.log(currentAddress);
    this.addresscode = currentAddress;
    if (currentAddress) {
      // this.daiOwnMoney = parseFloat(
      //   await web3Utils.getDaiBalance(this.addresscode)
      // );
      this.signtype()


      //  console.log(this.daiOwnMoney.toFixed(6));


      // this.exchangeallowance()
    }
  },
  methods: {
    // 签名验证
    async signtype() {
      this.isloading = true;
      await sign
        .getauthorization(
          this.addresscode
        )
        .then((res) => {

          if (res.code == 0) {
            //   this.users();
            //   // this.exchangeallowance();
            //   this.limitAllInvestAmount();
            //   this.minInvestAmount();
          }
          this.isloading = false;
        });
    },


  },
  computed: {},
};
</script>
